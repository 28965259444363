 const Spinner = ({ loading, width ,borderWidth,top,left}) => {
  const customStyle = width 
    ? { width: width, height: width, borderWidth: borderWidth, marginTop:top , marginLeft:left} 
    : {};
  return !loading ? (
    <span  class="loader"></span>
  ) : null;
};

export default Spinner;
